.header {
  background-color: #dbcc73f7;
  grid-template-rows: 5rem 5rem;
  grid-template-columns: 1fr;
  height: 9.6rem;
  padding: 0 4.8rem;
  display: grid;
  position: relative;
}

.logo {
  height: 30rem;
  display: inline-block;
  transform: translate(-9%, 12%);
}

.logo-container:focus {
  box-shadow: 0 0 0 .8rem #201f1d80 !important;
}

.logo-flex {
  justify-content: flex-start;
  align-items: center;
  gap: 2rem;
  display: flex;
}

.logo-flex form {
  gap: 2rem;
}

.search {
  justify-content: flex-start;
  align-items: center;
  display: flex;
}

.search label {
  color: #333;
  font-size: 1.4rem;
  font-weight: 600;
}

.search input {
  border-radius: 10px;
  width: 28rem;
  height: 2.5rem;
  padding-left: 1rem;
}

.search-mobile input {
  border-radius: 10px;
  width: 32rem;
  height: 4rem;
  padding-left: 1rem;
}

#search-icon {
  outline: invert;
  background-color: inherit;
  border: none;
  width: 2rem;
  height: 2rem;
  display: inline-block;
  transform: translate(-230%);
}

.icon-none {
  display: none;
}

.hoursContainer, .contactContainer {
  place-content: center space-between;
  display: flex;
}

.icon-clock, .icon-cell-phone {
  width: 2rem;
  height: 2rem;
}

.logo-flex span {
  color: #333;
  font-size: 1.4rem;
  font-weight: 600;
}

.logo-flex a {
  text-decoration: none;
}

body:before {
  content: "Tổ Yến Phạm Gia Chào Quý Khách";
  z-index: -9999;
  color: #444;
  background-color: #96ccd9;
  width: 100%;
  padding: 1.6rem;
  font-size: 16px;
  font-weight: bold;
  display: inline-block;
}

.main-nav {
  grid-row: 2 / 3;
}

.main-nav-list {
  justify-content: flex-start;
  align-items: center;
  gap: 3.2rem;
  list-style: none;
  display: flex;
}

.main-nav-link:visited, .main-nav-link:link {
  color: #333;
  max-width: 15.4rem;
  font-size: 1.4rem;
  font-weight: 600;
  text-decoration: none;
  transition: all .3s;
  display: inline-block;
}

.main-nav-link:hover, .main-nav-link:active {
  color: #96ccd9;
}

.main-nav-link.nav-cta:link, .main-nav-link.nav-cta:visited {
  color: #fff;
  background-color: #96ccd9;
  border-radius: 9px;
  padding: 1.2rem 2.4rem;
}

.main-nav-link.nav-cta:hover, .main-nav-link.nav-cta:active {
  background-color: #96ccd9;
}

.btn-mobile-nav {
  cursor: pointer;
  background: none;
  border: none;
  display: none;
}

.icon-mobile-nav {
  color: #333;
  width: 4.8rem;
  height: 4.8rem;
}

.icon-mobile-nav[name="close-outline"], .search-mobile {
  display: none;
}

.sticky .header {
  z-index: 999;
  background-color: #dbcc73f7;
  width: 100%;
  height: 10rem;
  padding-top: 0;
  padding-bottom: 0;
  position: fixed;
  top: 0;
  bottom: 0;
  box-shadow: 0 1.2rem 3.2rem #00000008;
}

.sticky .section-hero {
  margin-top: 9.6rem;
}

.hero {
  grid-template-columns: 1fr 1fr;
  align-items: center;
  gap: 9.6rem;
  max-width: 130rem;
  margin: 0 auto;
  display: grid;
}

.hero-img {
  width: 100%;
}

.section-hero {
  background-color: #96ccd9;
  padding: 9.6rem 0;
}

.hero-description {
  margin-bottom: 4.8rem;
  font-size: 2rem;
  font-weight: 400;
  line-height: 1.6;
}

.delivered-nests {
  align-items: center;
  gap: 1.6rem;
  margin-top: 8rem;
  display: flex;
}

.delivered-imgs {
  display: flex;
}

.delivered-imgs img {
  border: 3px solid #fdf2e9;
  border-radius: 50%;
  width: 7rem;
  height: 7rem;
  margin-right: -1.6rem;
}

.delivered-imgs img:last-child {
  margin-right: 0;
}

.delivered-text {
  font-size: 1.8rem;
  font-weight: 600;
}

.delivered-text span {
  color: #cf711f;
  font-weight: 700;
}

.section-mission {
  background-color: #5cafc4;
  padding: 10.6rem 0;
}

.mission-background {
  background-image: url("our mission.b0565fea.jpg");
  background-repeat: no-repeat;
  background-size: 100% 100%;
}

.mission-description {
  color: #fff;
  font-size: 1.8rem;
  line-height: 1.8;
}

.section-why {
  background-color: #96d9c4;
  padding: 9.6rem 0;
  margin-bottom: 0 !important;
}

.why-img-box {
  justify-content: center;
  align-items: center;
  display: flex;
  position: relative;
}

.why-img {
  border-radius: 10%;
  width: 80%;
}

.why-description {
  font-size: 1.8rem;
  line-height: 1.8;
}

.section-product {
  background-color: #cbe5ec;
  margin-bottom: 0;
  padding: 9.6rem 0;
}

.product {
  border-radius: .11px;
  box-shadow: 0 12rem 4.8rem #00000013;
}

.test-next-image {
  margin-bottom: 5rem;
}

.product-img-container {
  width: 40rem;
  height: 40rem;
  position: relative;
  overflow: hidden;
}

.product-img-container picture, .product-img-container img {
  object-fit: cover;
  opacity: 1;
  border-radius: 5px;
  width: 100%;
  height: 100%;
  transition: opacity 1s ease-in-out;
  position: absolute;
  top: 0;
  left: 0;
}

.product picture.active, .product picture img.active {
  opacity: 1 !important;
}

.product:hover {
  transform: translateY(-1.2rem);
  box-shadow: 0 3.2rem 6.4rem #0000000f;
}

.product-content {
  padding: 3.2rem 4.8rem 4.8rem;
}

.product-tags {
  gap: .4rem;
  margin-bottom: 1.2rem;
  display: flex;
}

.tag {
  text-transform: uppercase;
  color: #333;
  border-radius: 100px;
  padding: .4rem .8rem;
  font-size: 1.2rem;
  font-weight: 600;
  display: inline-block;
}

.product-title {
  color: #333;
  margin-bottom: 3.2rem;
  font-size: 2.2rem;
  font-weight: 600;
}

.product-attributes {
  flex-direction: column;
  gap: 2rem;
  list-style: none;
  display: flex;
}

.product-attribute {
  align-items: center;
  gap: 1.6rem;
  font-size: 1.8rem;
  display: flex;
}

.product-icon {
  color: #e67e22;
  width: 3.6rem;
  height: 3.6rem;
}

.product-icon-star {
  stroke: #444;
  width: 2.4rem;
  height: 2.4rem;
}

.product-description {
  flex-basis: 11em;
  line-height: 1.2;
}

.product-img {
  width: 100%;
}

.section-testimonials {
  background-color: #fdf2e9;
  grid-template-columns: 55fr 45fr;
  align-items: center;
  display: grid;
}

.testimonials-container {
  padding: 9.6rem;
}

.testimonials {
  grid-template-columns: 1fr 1fr;
  gap: 4.8rem 8rem;
  display: grid;
}

.testimonial-img {
  border-radius: 50%;
  width: 6.4rem;
  margin-bottom: 1.2rem;
}

.testimonial-text {
  margin-bottom: 1.6rem;
  font-size: 1.8rem;
  line-height: 1.8;
}

.testimonial-name {
  color: #6f6f6f;
  font-size: 1.6rem;
}

.gallery {
  grid-template-columns: repeat(3, 1fr);
  gap: 1.6rem;
  padding: 1.6rem;
  display: grid;
}

.gallery-item {
  border-radius: 5px;
  overflow: hidden;
}

.gallery-item img {
  width: 100%;
  transition: all .4s;
  display: block;
}

.gallery-item img:hover {
  transform: scale(1.1);
}

.footer {
  border-top: 1px solid #eee;
  padding: 8rem 0 12.8rem;
}

.grid--footer {
  grid-template-columns: 20rem 1fr 4fr 3fr 4fr !important;
  gap: 5rem 3rem !important;
}

.logo-col {
  flex-direction: column;
  display: flex;
}

.footer-logo {
  color: #00f;
  margin-bottom: 3.2rem;
  display: block;
}

.footer-logo-img {
  height: 70%;
}

.social-links {
  gap: 2.4rem;
  list-style: none;
  display: flex;
}

.social-icon {
  width: 2.4rem;
  height: 2.4rem;
}

.social-icon img {
  display: inline-block;
}

.copyright {
  color: #767676;
  margin-top: auto;
  font-size: 1.4rem;
  line-height: 1.6;
}

.footer-heading {
  margin-bottom: 4rem;
  font-size: 1.8rem;
  font-weight: 500;
}

.contacts {
  font-size: 1.6rem;
  font-style: normal;
  line-height: 1.6;
}

.address {
  margin-bottom: 2.4rem;
}

.footer-nav {
  flex-direction: column;
  gap: 2.4rem;
  list-style: none;
  display: flex;
}

.footer-link:link, .footer-link:visited {
  color: #767676;
  font-size: 1.6rem;
  text-decoration: none;
  transition: all .3s;
}

.footer-link:hover, .footer-link:active {
  color: #555;
}

#map {
  flex: 1;
  width: 26rem;
  height: 100%;
}

.leaflet-popup .leaflet-popup-content {
  font-size: .8rem;
  font-weight: 600;
}

.leaflet-popup .leaflet-popup-content-wrapper {
  border-radius: 5px;
}

.widget-container {
  z-index: 100;
  transition: all .5s;
  position: fixed;
  bottom: 10%;
  left: 3%;
}

.widget-collapse a {
  width: 5rem !important;
}

.widget-collapse span {
  display: none !important;
}

.zalo-widget:hover, .phone-widget:hover {
  width: 23rem !important;
}

.zalo-widget:hover .widget-cantact-number, .phone-widget:hover .widget-cantact-number {
  display: inline-block !important;
}

.zalo-widget {
  background-color: #0000ffc5;
  border-radius: 30px;
  align-content: center;
  gap: 1.5rem;
  width: 23rem;
  height: 5rem;
  text-decoration: none;
  display: flex;
}

.zalo-rings {
  color: #228be6;
  border: 2px solid;
  border-radius: 50%;
  width: 5rem;
  height: 5rem;
  animation-name: growZalo;
  animation-duration: 1s;
  animation-iteration-count: infinite;
  display: inline-block;
  position: absolute;
}

@keyframes growZalo {
  0% {
    transform: rotate(0);
  }

  50% {
    border: 2px solid #228be67c;
    transform: scale(.5);
  }

  100% {
    border: 2px solid #228be621;
    transform: scale(1.5);
  }
}

.zalo-widget img {
  background-color: #fff;
  border-radius: 50%;
  width: 3.6rem;
  height: 3.6em;
  padding: .3rem;
  animation-name: rotate;
  animation-duration: .8s;
  animation-iteration-count: infinite;
  position: relative;
  transform: translate(20%, 18%);
}

@keyframes rotate {
  0% {
    transform: translate(20%, 18%)rotate(0);
  }

  100% {
    transform: translate(20%, 18%)rotate(-30deg);
  }
}

.zalo-widget span, .phone-widget span {
  color: #fff;
  font-size: 2rem;
  font-weight: 500;
  transform: translate(7%, 25%);
  box-shadow: none !important;
}

.phone-widget {
  background-color: #f03e3ec5;
  border-radius: 30px;
  align-content: center;
  gap: 1.5rem;
  width: 23rem;
  height: 5rem;
  margin-top: 10rem;
  text-decoration: none;
  display: flex;
}

.phone-widget img {
  background-color: #fff;
  border-radius: 50%;
  width: 3.6rem;
  height: 3.6em;
  padding: .3rem;
  animation-name: rotate;
  animation-duration: .8s;
  animation-iteration-count: infinite;
  position: relative;
  transform: translate(20%, 18%);
}

.phone-rings {
  color: #f03e3e;
  border: 2px solid;
  border-radius: 50%;
  width: 5rem;
  height: 5rem;
  animation-name: growPhone;
  animation-duration: 1s;
  animation-iteration-count: infinite;
  display: inline-block;
  position: absolute;
}

@keyframes growPhone {
  0% {
    transform: rotate(0);
  }

  50% {
    border: 2px solid #ff3e3e7c;
    transform: scale(.5);
  }

  100% {
    border: 2px solid #f03e3e21;
    transform: scale(1.5);
  }
}

.widgets-container-hide {
  opacity: 0;
  pointer-events: none;
  visibility: hidden;
}

.privacy {
  background-color: #cbe5ec;
  padding-top: 5rem;
  padding-bottom: 5rem;
}

.privacy h1 {
  margin-bottom: 3rem !important;
}

.privacy h3 {
  padding-top: 3rem;
}

.privacy-description {
  margin-bottom: 4.8rem;
  font-size: 2rem;
  font-weight: 300;
  line-height: 1.6;
}

.privacy-text {
  margin-bottom: 2rem;
  font-size: 1.6rem;
  font-weight: 400;
  line-height: 1.5;
}

.privacy-list {
  flex-direction: column;
  align-items: flex-start;
  padding-left: 4rem;
  display: flex;
}

.privacy-list li:last-child {
  margin-bottom: 2rem;
}

.privacy-subheading {
  text-transform: uppercase;
  letter-spacing: .75px;
  margin-bottom: 1.6rem;
  font-size: 1.6rem;
  font-weight: 500;
  display: block;
}

/*# sourceMappingURL=index.44fb237d.css.map */
