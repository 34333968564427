/* Header */
.header {
  display: grid;
  grid-template-columns: 1fr;
  /* grid-template-rows: 1fr 1fr; */
  grid-template-rows: 5rem 5rem;
  /* justify-content: space-between; */
  /* align-items: center; */
  /* background-color: rgba(219, 204, 115, 0.267); */
  background-color: rgba(219, 204, 115, 0.97);
  height: 9.6rem;
  padding: 0 4.8rem;
  position: relative;
}

.logo {
  height: 30rem;
  display: inline-block;
  transform: translate(-9%, 12%);
}

.logo-container:focus {
  box-shadow: 0 0 0 0.8rem rgba(32, 31, 29, 0.5) !important;
}

.logo-flex {
  display: flex;
  align-items: center;
  justify-content: flex-start;
  gap: 2rem;
  /* flex-direction: column; */
}

.logo-flex form {
  gap: 2rem;
}

.search {
  display: flex;
  align-items: center;
  justify-content: flex-start;
}

.search label {
  color: #333;
  font-weight: 600;
  font-size: 1.4rem;
}

.search input {
  border-radius: 10px;
  height: 2.5rem;
  width: 28rem;
  padding-left: 1rem;
}

.search-mobile input {
  border-radius: 10px;
  height: 4rem;
  width: 32rem;
  padding-left: 1rem;
}

#search-icon {
  transform: translate(-230%);
  height: 2rem;
  width: 2rem;
  border: none;
  outline: invert;
  background-color: inherit;
  display: inline-block;
  /* cursor: pointer; */
}

.icon-none {
  display: none;
}

.hoursContainer,
.contactContainer {
  display: flex;
  align-content: center;
  justify-content: space-between;
}

.icon-clock,
.icon-cell-phone {
  height: 2rem;
  width: 2rem;
}

.logo-flex span {
  color: #333;
  font-weight: 600;
  font-size: 1.4rem;
}

.logo-flex a {
  text-decoration: none;
}

/* Welcome banner */
body::before {
  content: 'Tổ Yến Phạm Gia Chào Quý Khách';
  width: 100%;
  padding: 1.6rem;
  z-index: -9999;

  background-color: rgb(150, 204, 217);
  color: #444;
  font-size: 16px;
  font-weight: bold;
  display: inline-block;
}

/* Navigation */
.main-nav {
  grid-row: 2/3;
}

.main-nav-list {
  list-style: none;
  display: flex;
  gap: 3.2rem;
  align-items: center;
  justify-content: flex-start;
}

.main-nav-link:visited,
.main-nav-link:link {
  display: inline-block;
  text-decoration: none;
  color: #333;
  font-weight: 600;
  font-size: 1.4rem;
  transition: all 0.3s;
  max-width: 15.4rem;
}
.main-nav-link:hover,
.main-nav-link:active {
  color: #96ccd9;
}

.main-nav-link.nav-cta:link,
.main-nav-link.nav-cta:visited {
  padding: 1.2rem 2.4rem;
  border-radius: 9px;
  color: #fff;
  background-color: #96ccd9;
}
.main-nav-link.nav-cta:hover,
.main-nav-link.nav-cta:active {
  background-color: #96ccd9;
}

/* Mobile */
.btn-mobile-nav {
  border: none;
  background: none;
  cursor: pointer;

  display: none;
}

.icon-mobile-nav {
  height: 4.8rem;
  width: 4.8rem;
  color: #333;
}

.icon-mobile-nav[name='close-outline'] {
  display: none;
}

.search-mobile {
  display: none;
}

/* Sticky Navigation  */
.sticky .header {
  position: fixed;
  top: 0;
  bottom: 0;
  width: 100%;
  height: 10rem;
  padding-top: 0;
  padding-bottom: 0;
  background-color: rgba(219, 204, 115, 0.97);
  z-index: 999;
  box-shadow: 0 1.2rem 3.2rem rgba(0, 0, 0, 0.03);
}

.sticky .section-hero {
  margin-top: 9.6rem;
}

/* Hero Section */
.hero {
  max-width: 130rem;
  margin: 0 auto;
  display: grid;
  grid-template-columns: 1fr 1fr;
  align-items: center;
  gap: 9.6rem;
}

.hero-img {
  width: 100%;
}

.section-hero {
  background-color: #96ccd9;
  padding: 9.6rem 0;
}

.hero-description {
  font-size: 2rem;
  font-weight: 400;
  line-height: 1.6;
  margin-bottom: 4.8rem;
}

.delivered-nests {
  margin-top: 8rem;
  display: flex;
  align-items: center;
  gap: 1.6rem;
}

.delivered-imgs {
  display: flex;
}

.delivered-imgs img {
  height: 7rem;
  width: 7rem;
  border-radius: 50%;
  margin-right: -1.6rem;
  border: 3px solid #fdf2e9;
}

.delivered-imgs img:last-child {
  margin-right: 0;
}

.delivered-text {
  font-size: 1.8rem;
  font-weight: 600;
}

.delivered-text span {
  color: #cf711f;
  font-weight: 700;
}

/* Section Mission */
.section-mission {
  padding: 10.6rem 0;
  background-color: #5cafc4;
}

.mission-background {
  background-image: url('../img/our\ mission.jpg');
  background-repeat: no-repeat;
  background-size: 100% 100%;
}

.mission-description {
  font-size: 1.8rem;
  line-height: 1.8;
  color: #fff;
}

/* Section why choose us */
.section-why {
  background-color: #96d9c4;
  padding: 9.6rem 0;
  margin-bottom: 0 !important;
}

.why-description {
  font-size: 1.8rem;
  line-height: 1.8;
}

.why-img-box {
  position: relative;

  display: flex;
  align-items: center;
  justify-content: center;
}

.why-img {
  width: 80%;
  border-radius: 10%;
  /* z-index: 10; */
}

.why-description {
  font-size: 1.8rem;
  line-height: 1.8;
}

/* Product Section */
.section-product {
  padding: 9.6rem 0;
  background-color: #cbe5ec;
  margin-bottom: 0;
}

.product {
  box-shadow: 0 12rem 4.8rem rgba(0, 0, 0, 0.075);
  border-radius: 0.11px;
}

.test-next-image {
  margin-bottom: 5rem;
}

.product-img-container {
  overflow: hidden;
  position: relative;
  width: 40rem;
  height: 40rem;
}

.product-img-container picture,
.product-img-container img {
  width: 100%;
  height: 100%;
  position: absolute;
  top: 0;
  left: 0;
  object-fit: cover;
  opacity: 1;
  transition: opacity 1s ease-in-out;
  border-radius: 5px;
}

.product picture.active,
.product picture img.active {
  opacity: 1 !important;
}

.product:hover {
  transform: translateY(-1.2rem);
  box-shadow: 0 3.2rem 6.4rem rgba(0, 0, 0, 0.06);
}

.product-content {
  padding: 3.2rem 4.8rem 4.8rem 4.8rem;
}

.product-tags {
  margin-bottom: 1.2rem;
  display: flex;
  gap: 0.4rem;
}

.tag {
  display: inline-block;
  padding: 0.4rem 0.8rem;
  font-size: 1.2rem;
  text-transform: uppercase;
  color: #333;
  border-radius: 100px;
  font-weight: 600;
}

.product-title {
  font-size: 2.2rem;
  color: #333;
  font-weight: 600;
  margin-bottom: 3.2rem;
}

.product-attributes {
  list-style: none;
  display: flex;
  /* align-content: flex-start; */
  flex-direction: column;
  gap: 2rem;
}

.product-attribute {
  font-size: 1.8rem;
  display: flex;
  /* flex-shrink: 0; */
  align-items: center;
  /* align-items: flex-start; */
  gap: 1.6rem;
}

.product-icon {
  /* align-self: flex-start; */
  height: 3.6rem;
  width: 3.6rem;
  color: #e67e22;
}

.product-icon-star {
  height: 2.4rem;
  width: 2.4rem;
  stroke: #444;
}

.product-description {
  flex-basis: 11em;
  line-height: 1.2;
}

.product-img {
  width: 100%;
}

/* section-testimonials */

.section-testimonials {
  background-color: #fdf2e9;
  display: grid;
  grid-template-columns: 55fr 45fr;
  align-items: center;
}

.testimonials-container {
  padding: 9.6rem;
}

.testimonials {
  display: grid;
  grid-template-columns: 1fr 1fr;
  row-gap: 4.8rem;
  column-gap: 8rem;
}

.testimonial-img {
  width: 6.4rem;
  border-radius: 50%;
  margin-bottom: 1.2rem;
}

.testimonial-text {
  font-size: 1.8rem;
  line-height: 1.8;
  margin-bottom: 1.6rem;
}

.testimonial-name {
  font-size: 1.6rem;
  color: #6f6f6f;
}

.gallery {
  display: grid;
  grid-template-columns: repeat(3, 1fr);
  gap: 1.6rem;
  padding: 1.6rem;
}

.gallery-item {
  overflow: hidden;
  border-radius: 5px;
}

.gallery-item img {
  display: block;
  width: 100%;
  transition: all 0.4s;
}

.gallery-item img:hover {
  transform: scale(1.1);
}

/* Footer */
.footer {
  padding: 8rem 0 12.8rem 0;
  border-top: 1px solid #eee;
}

.grid--footer {
  grid-template-columns: 20rem 1fr 4fr 3fr 4fr !important;
  row-gap: 5rem !important;
  column-gap: 3rem !important;
}

.logo-col {
  display: flex;
  flex-direction: column;
}

.footer-logo {
  display: block;
  margin-bottom: 3.2rem;
  color: blue;
}

.footer-logo-img {
  height: 70%;
}

.social-links {
  list-style: none;
  display: flex;
  gap: 2.4rem;
}

.social-icon {
  height: 2.4rem;
  width: 2.4rem;
}

.social-icon img {
  display: inline-block;
}

.copyright {
  font-size: 1.4rem;
  line-height: 1.6;
  color: #767676;
  margin-top: auto;
}

.footer-heading {
  font-size: 1.8rem;
  font-weight: 500;
  margin-bottom: 4rem;
}

.contacts {
  font-style: normal;
  font-size: 1.6rem;
  line-height: 1.6;
}

.address {
  margin-bottom: 2.4rem;
}

.footer-nav {
  list-style: none;
  display: flex;
  flex-direction: column;
  gap: 2.4rem;
}

.footer-link:link,
.footer-link:visited {
  text-decoration: none;
  font-size: 1.6rem;
  color: #767676;
  transition: all 0.3s;
}

.footer-link:hover,
.footer-link:active {
  color: #555;
}

#map {
  flex: 1;
  height: 100%;
  width: 26rem;
}

.leaflet-popup .leaflet-popup-content {
  font-size: 0.8rem;
  font-weight: 600;
}

.leaflet-popup .leaflet-popup-content-wrapper {
  border-radius: 5px;
}

/* Contact Widgets */
.widget-container {
  position: fixed;
  bottom: 10%;
  left: 3%;
  z-index: 100;
  transition: all 0.5s;
}

.widget-collapse a {
  width: 5rem !important;
}

.widget-collapse span {
  display: none !important;
}

.zalo-widget:hover {
  width: 23rem !important;
}

.phone-widget:hover {
  width: 23rem !important;
}

.zalo-widget:hover .widget-cantact-number,
.phone-widget:hover .widget-cantact-number {
  display: inline-block !important;
}

.zalo-widget {
  display: flex;
  align-content: center;
  gap: 1.5rem;
  width: 23rem;
  height: 5rem;
  text-decoration: none;
  background-color: rgba(0, 0, 255, 0.774);
  border-radius: 30px;
}

.zalo-rings {
  display: inline-block;
  color: rgb(34, 139, 230);
  border-radius: 50%;
  border: 2px solid currentColor;
  position: absolute;
  height: 5rem;
  width: 5rem;
  animation-name: growZalo;
  animation-duration: 1s;
  animation-iteration-count: infinite;
}

@keyframes growZalo {
  0% {
    transform: rotate(0);
  }
  50% {
    transform: scale(50%);
    border: 2px solid rgba(34, 139, 230, 0.486);
  }
  100% {
    transform: scale(150%);
    border: 2px solid rgba(34, 139, 230, 0.13);
  }
}

.zalo-widget img {
  position: relative;
  height: 3.6em;
  width: 3.6rem;
  transform: translate(20%, 18%);
  background-color: #fff;
  padding: 0.3rem;
  border-radius: 50%;
  animation-name: rotate;
  animation-duration: 0.8s;
  animation-iteration-count: infinite;
}

@keyframes rotate {
  0% {
    transform: translate(20%, 18%) rotate(0);
  }
  100% {
    transform: translate(20%, 18%) rotate(-30deg);
  }
}

.zalo-widget span,
.phone-widget span {
  color: #fff;
  font-weight: 500;
  font-size: 2rem;
  transform: translate(7%, 25%);
  box-shadow: none !important;
}

.phone-widget {
  margin-top: 10rem;
  text-decoration: none;
  display: flex;
  align-content: center;
  gap: 1.5rem;
  width: 23rem;
  height: 5rem;
  background-color: rgb(240, 62, 62, 0.774);
  border-radius: 30px;
}

.phone-widget img {
  position: relative;
  height: 3.6em;
  width: 3.6rem;
  transform: translate(20%, 18%);
  background-color: #fff;
  padding: 0.3rem;
  border-radius: 50%;
  animation-name: rotate;
  animation-duration: 0.8s;
  animation-iteration-count: infinite;
}

.phone-rings {
  display: inline-block;
  color: rgb(240, 62, 62);
  border-radius: 50%;
  border: 2px solid currentColor;
  position: absolute;
  height: 5rem;
  width: 5rem;
  animation-name: growPhone;
  animation-duration: 1s;
  animation-iteration-count: infinite;
}

@keyframes growPhone {
  0% {
    transform: rotate(0);
  }
  50% {
    transform: scale(50%);
    border: 2px solid rgba(3240, 62, 62, 0.486);
  }
  100% {
    transform: scale(150%);
    border: 2px solid rgba(240, 62, 62, 0.13);
  }
}

.widgets-container-hide {
  opacity: 0;
  pointer-events: none;
  visibility: hidden;
}

/* Privacy */
.privacy {
  background-color: #cbe5ec;
  padding-top: 5rem;
  padding-bottom: 5rem;
}

.privacy h1 {
  margin-bottom: 3rem !important;
}

.privacy h3 {
  padding-top: 3rem;
}

.privacy-description {
  font-size: 2rem;
  font-weight: 300;
  line-height: 1.6;
  margin-bottom: 4.8rem;
}

.privacy-text {
  font-size: 1.6rem;
  line-height: 1.5;
  font-weight: 400;
  margin-bottom: 2rem;
}

.privacy-list {
  padding-left: 4rem;
  display: flex;
  flex-direction: column;
  align-items: flex-start;
}

.privacy-list li:last-child {
  margin-bottom: 2rem;
}

.privacy-subheading {
  display: block;
  font-size: 1.6rem;
  font-weight: 500;
  /* color: #4444; */
  text-transform: uppercase;
  margin-bottom: 1.6rem;
  letter-spacing: 0.75px;
}
